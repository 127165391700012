import {
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { JsonConfig } from '../modules/MainApp/types/SettingsType';
import { ApiType } from '../types/apiType';

interface IProps {
  children: ReactNode;
}

type ConfigProviderContextType = {
  config: JsonConfig | null;
  setConfig: (config: JsonConfig) => void;
  data: ApiType | null;
  setData: (data: ApiType) => void;
};

export const ConfigProviderContext = createContext<ConfigProviderContextType>({
  config: null,
  setConfig: () => undefined,
  data: null,
  setData: () => undefined,
});

const ConfigProvider = ({ children }: IProps) => {
  const [config, setConfig] = useState<JsonConfig | null>(null);
  const [data, setData] = useState<ApiType | null>(null);

  useEffect(() => {
    const getConfig = async (): Promise<JsonConfig> => {
      const result = await fetch(process.env.REACT_APP_CONFIG_FILE_PATH ?? '', {
        method: 'GET',
      });

      const data = await result.json();
      return data as JsonConfig;
    };

    getConfig()
      .then((res) => {
        if (res) {
          setConfig(res);
        }
      });
  }, []);

  const value = useMemo(() => ({
    config,
    setConfig,
    data,
    setData,
  }), [config, setConfig, data, setData]);

  return (
    <ConfigProviderContext.Provider
      value={value}
    >
      { children }
    </ConfigProviderContext.Provider>
  );
};

export default ConfigProvider;
