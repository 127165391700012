import { convertSvgToCoordinates } from '../../../Converter/utils';
import { SettingInterface } from '../../interfaces/SettingsInterface';

export class WordSetting implements SettingInterface {
  public word: string = '';

  public fontNumber = 1;

  constructor(word: string) {
    this.word = word;
  }

  public setFontNumber(font?: number): void {
    this.fontNumber = font ?? 0;
  }

  public check(): boolean {
    return /^[a-z]$/i.test(this.word);
  }

  public getIdValue(): string {
    return this.fontNumber === 0 || this.word === ' ' ? this.word : `${this.word}.${this.fontNumber}`;
  }

  public getPathSummary(): string[] {
    const editWord = this.getIdValue();
    return this.writeValue(editWord);
  }

  public writeValue(value: string): string[] {
    const { linePath, polylinePath } = convertSvgToCoordinates(value);
    return [linePath, polylinePath].flat(1);
  }
}
