const splitToArray = (text: string[], array: number[]) => {
  const resultIndex: number[][] = [];
  const resultWidth: number[][] = [];
  let temp: number[] = [];
  let indexTempArray: number[] = [];

  let count = 0;
  for (let i = 0; i < text.length; i++) {
    if (text[i] !== '/n') {
      temp.push(array[i - count]);
      indexTempArray.push(i - count);
    } else {
      resultWidth.push(temp);
      count += 1;
      resultIndex.push(indexTempArray);
      temp = [];
      indexTempArray = [];
    }
    if (i === text.length - 1) {
      resultIndex.push(indexTempArray);
      resultWidth.push(temp);
    }
  }

  return { resultIndex, resultWidth };
};

export default splitToArray;
