import { WordSetting } from './WordSetting';
import { SymbolSetting } from './SymbolSetting';
import { NumberSetting } from './NumberSetting';
import { SettingInterface } from '../../interfaces/SettingsInterface';

class Settings {
  private settingValue;

  private wordSettings: SettingInterface;

  private symbolSettings: SettingInterface;

  private numberSetting: SettingInterface;

  constructor(valueSetting: string) {
    this.settingValue = valueSetting;
    this.wordSettings = new WordSetting(valueSetting);
    this.symbolSettings = new SymbolSetting(valueSetting);
    this.numberSetting = new NumberSetting(valueSetting);
  }

  public checkAndReturnSettings(): SettingInterface | undefined {
    let setting: SettingInterface | undefined;

    if (this.numberSetting.check()) setting = this.numberSetting;
    if (this.wordSettings.check()) setting = this.wordSettings;
    if (this.symbolSettings.check()) setting = this.symbolSettings;

    return setting;
  }
}

export default Settings;
