export type BrushPointOptions = {
  x?: number;
  y?: number;
  density?: number;
}

class BrushPoint {
  public x: number;

  public y: number;

  public density = 0;

  constructor(options: BrushPointOptions) {
    this.x = options.x ?? 0;
    this.y = options.y ?? 0;
    this.density = options.density ?? 0;
  }

  public getDistanceToBrushPoint(targetBrushPoint: BrushPoint): number {
    return Math.sqrt((targetBrushPoint.x - this.x) ** 2 + (targetBrushPoint.y - this.y) ** 2);
  }

  public getAngleToBrushPoint(targetBrushPoint: BrushPoint): number {
    return Math.atan2(targetBrushPoint.x - this.x, targetBrushPoint.y - this.y);
  }
}

export default BrushPoint;
