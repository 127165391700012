import ParserWord from './ParserWord';
import { MainApp } from '../../MainApp';
import { SvgPainter } from '../../../Converter/SvgPainter';
import Settings from '../ParseTextSettings/Settings';
import { convertSvgToCoordinates } from '../../../Converter/utils';

export interface ParserTextOptions {
  mainApp: MainApp;
  painter: SvgPainter;
}

class ParserText {
  public parserWord: ParserWord;

  public painter: SvgPainter;

  private mainApp: MainApp;

  public startY = 0;

  private trY = 0;

  public isCenter = false;

  public translateCenter: any = [];

  public translatesY: number[] = [];

  public spacing: number = 0;

  public currentTextIndex = 0;

  public kerning: number = 0;

  public wordHeight = 356;

  public summaryPhraseDuration: number = 0;

  constructor(options: ParserTextOptions) {
    this.parserWord = new ParserWord({
      mainApp: options.mainApp,
      painter: options.painter,
    });
    this.painter = options.painter;
    this.mainApp = options.mainApp;
  }

  private async animateText(phrase: string[]): Promise<boolean> {
    const index = this.currentTextIndex;
    const item = phrase[this.currentTextIndex];
    if (!item) return false;
    const findIndex = this.translatesY.find((item) => item === index);
    const isCapacity = this.parserWord.checkCapacity(item.toLowerCase(), 0);
    if (!this.isCenter) {
      this.parserWord.translateSentence(index);
    } else if (this.isCenter && !!findIndex) {
      const { x } = this.translateCenter[this.trY];
      const y = this.startY + this.wordHeight * (this.trY + 1);
      this.trY += 1;
      this.mainApp.translateY = y + 30 + this.spacing;
      this.mainApp.translateX = x + 40;
      this.parserWord.marginX = 0;
    }
    if (index !== 0 && isCapacity) this.mainApp.translateX += 50;
    await this.parserWord.parseWord(phrase[index], !this.mainApp.skipAnimation);
    this.currentTextIndex += 1;

    return Promise.resolve(true);
  }

  public parsePhrase(phrase: string[]): void {
    document.addEventListener('finishWord', () => {
      const bool = this.animateText(phrase);
      if (this.mainApp.skipAnimation && !bool) {
        setTimeout(() => {
          window.dispatchEvent(new CustomEvent('isAnimated'));
        }, 1000);
      }
    });

    this.animateText(phrase);
    this.countPhraseDuration(phrase);
  }

  public countPhraseDuration(phrase: string[]): void {
    let duration = 0;
    let count = 0;
    for (let i = 0; i < phrase.length; i++) {
      for (let j = 0; j < phrase[i].length; j++) {
        const settings = new Settings(phrase[i][j]);
        const currentSetting = settings.checkAndReturnSettings();
        const id = currentSetting?.getIdValue();
        if (id) {
          const font = Number(this.mainApp.fontSvg ? this.mainApp.fontSvg[count] : 1);
          currentSetting?.setFontNumber(font);
          const delay = this.parserWord.countDelayWord(id);
          duration += delay + 500;
          count += 1;
        }
      }
    }

    this.summaryPhraseDuration = duration;

    if (!this.mainApp.skipAnimation) {
      setTimeout(() => {
        window.dispatchEvent(new CustomEvent('isAnimated'));
      }, duration);
    }
  }

  public countLineTime(phrase: string[], summaryTime: number): void {
    const array: string[] = [];
    let count = 0;
    for (let i = 0; i < phrase.length; i++) {
      for (let j = 0; j < phrase[i].length; j++) {
        if (phrase[i] !== '/n') {
          const settings = new Settings(phrase[i][j]);
          const currentSetting = settings.checkAndReturnSettings();
          const font = Number(this.mainApp.fontSvg ? this.mainApp.fontSvg[count] : 1);
          currentSetting?.setFontNumber(font);
          const id = currentSetting?.getIdValue();
          if (id) {
            count += 1;
            array.push(id);
          }
        }
      }
    }

    let summary = 0;
    for (let i = 0; i < array.length; i++) {
      const { linePath, polylinePath } = convertSvgToCoordinates(array[i]);
      summary += [linePath, polylinePath].flat(1).length;
    }

    this.mainApp.lineTime = summaryTime / summary;
    this.parserWord.lineTime = (summaryTime / summary) + 50;
  }

  public destroy(): void {
    this.parserWord.destroy();
    this.summaryPhraseDuration = 0;
  }
}

export default ParserText;
