import { SvgPainter } from '../../../Converter/SvgPainter';
import { MainApp } from '../../MainApp';
import BrushPoint from '../../../BrushTools/Brushes/BrushPoint';
import { interpolateBrushPoints } from '../../../Converter/utils';

export type FreeDrawType = {
  mainApp: MainApp;
  painter: SvgPainter;
}

class FreeDraw {
  public painter?: SvgPainter = undefined;

  private ctx: CanvasRenderingContext2D | null = null;

  private lastPoint: BrushPoint = new BrushPoint({ x: 0, y: 0 });

  public isDrawing = false;

  private mainApp!: MainApp;

  constructor(options: FreeDrawType) {
    this.ctx = options.mainApp.ctx;
    if (!this.ctx) return;
    this.mainApp = options.mainApp;
    this.painter = options.painter;
  }

  public startDraw(x: number, y: number): void {
    if (this.painter) {
      this.lastPoint = new BrushPoint({ x, y });
      this.painter.selectedBrush.setPoint({ x, y });
      this.painter.selectedBrush.draw(0, 0);
    }
    this.isDrawing = true;
  }

  public drawing(x: number, y: number): void {
    if (this.isDrawing && this.painter) {
      const point = new BrushPoint({ x, y });
      this.painter.selectedBrush.increaseStrokeCount();
      // нельзя считать интерполируемые точки
      // поэтому здесь в методе draw стоит true, а счет активируется извне с помощью increaseStrokeCount()

      if (this.painter.selectedBrush.strokeCount > 1) {
        const { interpolatePoints } = interpolateBrushPoints([this.lastPoint, point], this.mainApp.fontSettings.interpolation);
        interpolatePoints.forEach((item) => {
          this.painter?.selectedBrush.setPoint({ x: item.x, y: item.y });
          this.painter?.selectedBrush.draw(0, 0, true);
        });
      } else {
        this.painter.selectedBrush.setPoint({ x, y });
        this.painter.selectedBrush.draw(0, 0, true);
      }

      this.lastPoint = point;
    }
  }

  public stopDrawing(): void {
    this.isDrawing = false;
    if (this.painter) {
      this.painter.selectedBrush.onEndOfLine();
    }
  }
}

export default FreeDraw;
