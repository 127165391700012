import GUI from 'lil-gui';
import { MainApp } from '../../MainApp/MainApp';

// todo: need refactoring params
class DebugGUI {
  public ctx: CanvasRenderingContext2D;

  private mainApp: MainApp;

  protected gui = new GUI({ title: 'Debug GUI' });

  constructor(
    ctx: CanvasRenderingContext2D,
    mainApp: MainApp,
  ) {
    this.ctx = ctx;
    this.mainApp = mainApp;
  }

  public initialize(): void {
    const folderName = 'Settings brush preset';
    const settingsFolder = this.gui.addFolder(folderName);

    settingsFolder.add(
      this.mainApp.getSettings(),
      'width',
      10,
      100,
      1,
    )
      .name('width')
      .onChange((value: number) => {
        this.mainApp.setBrushSettings({
          ...this.mainApp.brushSettings,
          width: value,
        });
      });

    settingsFolder.add(
      this.mainApp.getSettings(),
      'color',
      [
        '#FF0000',
        '#FFFFFF',
        '#001EFF',
        '#0A0A08',
        '#f7620c',
        '#FF5F1F',
      ],
    )
      .onChange((value: string) => {
        this.mainApp.setBrushSettings({
          ...this.mainApp.brushSettings,
          color: value,
        });
      });

    settingsFolder.add(
      this.mainApp.getSettings(),
      'opacity',
      0,
      1,
      0.01,
    )
      .name('opacity')
      .onChange((value: number) => {
        this.mainApp.setBrushSettings({
          ...this.mainApp.brushSettings,
          opacity: value,
        });
      });

    settingsFolder.add(
      this.mainApp.getSettings(),
      'dispersion',
      0,
      100,
      1,
    )
      .name('dispersion')
      .onChange((value: number) => {
        this.mainApp.setBrushSettings({
          ...this.mainApp.brushSettings,
          dispersion: value,
        });
      });

    const dripsFolder = this.gui.addFolder('Settings drips');

    dripsFolder.add(
      this.mainApp.getSettings(),
      'dripAmount',
      0,
      100,
      1,
    )
      .name('frequency')
      .onChange((value: number) => {
        this.mainApp.setBrushSettings({
          ...this.mainApp.brushSettings,
          dripAmount: value,
        });
      });

    dripsFolder.add(
      this.mainApp.getSettings(),
      'dripWidth',
      1,
      10,
      1,
    )
      .name('width')
      .onChange((value: number) => {
        this.mainApp.setBrushSettings({
          ...this.mainApp.brushSettings,
          dripWidth: value,
        });
      });

    dripsFolder.add(
      this.mainApp.getSettings(),
      'dripLength',
      1,
      20,
      1,
    )
      .name('length')
      .onChange((value: number) => {
        this.mainApp.setBrushSettings({
          ...this.mainApp.brushSettings,
          dripLength: value,
        });
      });

    dripsFolder.add(
      this.mainApp.getSettings(),
      'dripOpacity',
      1,
      100,
      1,
    )
      .name('opacity')
      .onChange((value: number) => {
        this.mainApp.setBrushSettings({
          ...this.mainApp.brushSettings,
          dripOpacity: value,
        });
      });

    dripsFolder.add(
      this.mainApp.getSettings(),
      'dripCurvature',
      0,
      20,
      1,
    )
      .name('curvature')
      .onChange((value: number) => {
        this.mainApp.setBrushSettings({
          ...this.mainApp.brushSettings,
          dripCurvature: value,
        });
      });

    dripsFolder.add(
      this.mainApp.getSettings(),
      'dripSpeed',
      0,
      40,
      1,
    )
      .name('speed')
      .onChange((value: number) => {
        this.mainApp.setBrushSettings({
          ...this.mainApp.brushSettings,
          dripSpeed: value,
        });
      });

    dripsFolder.add(
      this.mainApp.getSettings(),
      'endDripSize',
      0,
      15,
      1,
    )
      .name('end drip size')
      .onChange((value: number) => {
        this.mainApp.setBrushSettings({
          ...this.mainApp.brushSettings,
          endDripSize: value,
        });
      });

    dripsFolder.add(
      this.mainApp.getSettings(),
      'dripFlat',
      0,
      1,
      1,
    )
      .name('is flat')
      .onChange((value: number) => {
        this.mainApp.setBrushSettings({
          ...this.mainApp.brushSettings,
          dripFlat: value,
        });
      });

    const folderName2 = 'Settings font';
    const settingsFolder2 = this.gui.addFolder(folderName2);

    settingsFolder2.add(
      this.mainApp.fontSettings,
      'fontSize',
      1,
      5000,
      1,
    )
      .name('fontSize')
      .onChange((value: number) => {
        this.mainApp.setFontSettings({
          ...this.mainApp.fontSettings,
          fontSize: value,
        });
      });

    settingsFolder2.add(
      this.mainApp.fontSettings,
      'letterSpacing',
      100,
      5000,
      1,
    )
      .name('letterSpacing')
      .onChange((value: number) => {
        this.mainApp.setFontSettings({
          ...this.mainApp.fontSettings,
          letterSpacing: value,
        });
      });

    settingsFolder2.add(
      this.mainApp.fontSettings,
      'interpolation',
      1,
      60,
      0.1,
    )
      .name('interpolation')
      .onChange((value: number) => {
        this.mainApp.setFontSettings({
          ...this.mainApp.fontSettings,
          interpolation: value,
        });
      });
  }
}

export default DebugGUI;
