const parsePath = (s: string): any[] => {
  const points: any = [];
  const pointStrings = s.split(' ');
  pointStrings.forEach((pointString) => {
    const [x, y] = pointString.split(',');
    points.push({ x: Number(x), y: Number(y) });
  });
  return points;
};

export default parsePath;
