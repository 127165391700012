export enum EasingFunctionEnum {
  QuadraticInOut = 'QuadraticInOut',
  QuadraticOut = 'QuadraticOut',
  QuadraticIn = 'QuadraticIn',
  CubicInOut = 'CubicInOut',
  CubicIn = 'CubicIn',
  CubicOut = 'CubicOut',
  SinusoidalInOut = 'SinusoidalInOut',
  SinusoidalIn = 'SinusoidalIn',
  SinusoidalOut = 'SinusoidalOut',
  ExponentialInOut = 'ExponentialInOut',
  ExponentialIn = 'ExponentialIn',
  ExponentialOut = 'ExponentialOut',
  CircularInOut = 'CircularInOut',
  CircularIn = 'CircularIn',
  CircularOut = 'CircularOut',
}
