const randomMinusPlusRange = (min: number, max: number, isFloat?: boolean): number => {
  const difference = max - min;
  let rand = Math.random() * difference;
  if (!isFloat) {
    rand = Math.floor(rand);
  }
  return rand + min;
};

export default randomMinusPlusRange;
