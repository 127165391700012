export type GetBboxGrid = {
  width: number;
  height: number;
}

const getBboxGrid = (): GetBboxGrid => {
  const widthCanvas = 2480;
  const heightCanvas = 3508;

  const width = (widthCanvas * 80 / 100) / 2;
  const height = ((heightCanvas * 80 / 100) / 4) / 3;

  return {
    height,
    width,
  };
};

export default getBboxGrid;
