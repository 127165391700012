import { convertSvgToCoordinates, parseNumber } from '../../../Converter/utils';
import { SettingInterface } from '../../interfaces/SettingsInterface';

export class NumberSetting implements SettingInterface {
  public number: string = '';

  public fontNumber = 1;

  constructor(number: string) {
    this.number = number;
  }

  public setFontNumber(font?: number): void {
    if (font) {
      if (font < 1 || font > 2) this.fontNumber = 1;
      else this.fontNumber = 1;
    }
  }

  public check(): boolean {
    const number = Number(this.number);
    return number > -1;
  }

  public getIdValue(): string {
    return `${this.number}.${this.fontNumber}`;
  }

  public getPathSummary(): string[] {
    const editNumber = this.getIdValue();
    return this.writeValue(editNumber);
  }

  writeValue(value: string): string[] {
    const { linePath, polylinePath } = convertSvgToCoordinates(value);
    return [linePath, polylinePath].flat(1);
  }
}
