import * as TWEEN from '@tweenjs/tween.js';
import { Screen } from '../../data/ScreenResolution/Screen';
import GUI from '../BrushTools/GUI/GUI';
import { BrushSettings } from '../BrushTools/types/BrushSettings';
import { FontSettings } from '../CanvasText/type';
import { MainAppType } from './types/MainAppType';
import { DefaultBrushSettings } from './config/DefaultBrushSettings';
import { DefaultFontSettings } from './config/DefaultFontSettings';
import FreeDraw from './feature/FreeDraw/FreeDraw';
import { ConfigType } from './types/SettingsType';
import { SvgPainter } from '../Converter/SvgPainter';
import ParserText from './feature/Parser/ParserText';

export class MainApp {
  public options!: MainAppType;

  public ctx: CanvasRenderingContext2D | null = null;

  public parserText!: ParserText;

  public fontSettings!: FontSettings;

  public painter!: SvgPainter;

  public brushSettings!: BrushSettings;

  public freeDraw!: FreeDraw;

  public canvas: any = null;

  public positionData?: number = undefined;

  public isDebug = false;

  public previewId = '';

  public scale = 1;

  public translateX = 0;

  public fontSvg?: number[] = undefined;

  public lineTime = 900;

  public skipAnimation: boolean = true;

  public translateY = 440;

  public FontBuffer: ArrayBuffer | null = null;

  public word = '';

  public pictureImg: string = '';

  constructor(options: MainAppType) {
    this.options = options;
    const canvas = document.getElementById('canvas') as HTMLCanvasElement;
    this.canvas = canvas;
    this.ctx = canvas?.getContext('2d');
    if (!this.ctx) {
      return;
    }

    this.isDebug = options.isDebug ?? false;

    const effects = options?.config?.effects;
    if (effects?.length) {
      const index = Math.floor(Math.random() * effects.length);
      const effect = effects[index];
      this.brushSettings = effect.brushSettings || DefaultBrushSettings;
      this.fontSettings = effect.fontSettings || DefaultFontSettings;
      this.fontSettings.easingAnimation = effect.animationSettings?.easing || '';
    } else {
      this.brushSettings = DefaultBrushSettings;
      this.fontSettings = DefaultFontSettings;
    }
    if (options.height && options.width) {
      this.resizeCanvas(options.width, options.height);
    }
    if (options.config) {
      this.load(options.config.config, options.background, options.disableBackground || false, options.backgroundColor || '#ffffff');
    }

    if (options.isDebug) {
      this.initGUI();
    }
  }

  protected load(config: ConfigType, background: string, disable: boolean, color: string): Promise<void> {
    return Promise.all([
      this.setBackground(background, disable, color),
      this.loadFont(config.font),
    ]).then(() => {
      if (!this.ctx) return;
      this.update();
      this.painter = new SvgPainter({
        textCanvas: this.ctx,
        mainApp: this,
        brushSettings: this.brushSettings,
        fontSettings: this.fontSettings,
      });
      this.freeDraw = new FreeDraw({ mainApp: this, painter: this.painter });
      this.parserText = new ParserText({ mainApp: this, painter: this.painter });
      try {
        window.parent.postMessage(
          { sender: 'OG-Scene', status: 'ready' },
          '*',
        );
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('error post message');
      }
      window.dispatchEvent(new CustomEvent('isLoad'));
    });
  }

  // eslint-disable-next-line default-param-last
  public setBackground(image: string, disable = false, color?: string): Promise<void> {
    if (!disable) {
      const bgImg = new Image();
      this.pictureImg = image;
      bgImg.src = image;
      return new Promise((resolve, reject) => {
        bgImg.onload = () => {
          this.ctx?.drawImage(bgImg, 0, 0, bgImg.width, bgImg.height, 0, 0, 2480, 3508);
          resolve();
        };
        bgImg.onerror = (error) => {
          reject(error);
        };
      });
    }
    if (this.ctx) {
      this.ctx.beginPath();
      this.ctx.rect(0, 0, 2480, 3508);
      this.ctx.fillStyle = color || 'red';
      this.ctx.fill();
      return Promise.resolve();
    }
    return Promise.resolve();
  }

  public resizeCanvas(resizeWidthValue: number, resizeHeightValue: number): void {
    const width = 2480;
    const height = 3508;
    let scaleHeight = resizeWidthValue / width;

    let countWidth = width * scaleHeight;
    let countHeight = height * scaleHeight;

    while (countHeight > resizeHeightValue) {
      scaleHeight -= 0.001;
      countWidth = width * scaleHeight;
      countHeight = height * scaleHeight;
    }

    this.canvas.height = countHeight;
    this.canvas.width = countWidth;
    this.scale = scaleHeight;
    this.ctx?.scale(scaleHeight, scaleHeight);
  }

  private loadFont(font: string): Promise<void> {
    const buffer = fetch(font).then((response) => response.arrayBuffer());
    return buffer.then((data) => {
      this.FontBuffer = data;
    });
  }

  public initGUI(): void {
    if (!this.ctx) return;
    const gui = new GUI(this.ctx, this);
    gui.initialize();
  }

  public setFontSettings(options: FontSettings): void {
    this.fontSettings.fontSize = options.fontSize;
    this.fontSettings.letterSpacing = options.letterSpacing;
    this.fontSettings.interpolation = options.interpolation;
    this.painter.font.changeFontSize(options.fontSize);
  }

  public setBrushSettings(options: BrushSettings): void {
    this.brushSettings.opacity = options.opacity;
    this.brushSettings.width = options.width;
    this.brushSettings.color = options.color;
    this.brushSettings.dispersion = options.dispersion;
    this.brushSettings.dripWidth = options.dripWidth;
    this.brushSettings.dripAmount = options.dripAmount;
    this.brushSettings.dripLength = options.dripLength;
    this.brushSettings.dripOpacity = options.dripOpacity;
    this.brushSettings.dripCurvature = options.dripCurvature;
    this.brushSettings.endDripSize = options.endDripSize;
    this.brushSettings.dripSpeed = options.dripSpeed;
    this.brushSettings.dripFlat = options.dripFlat;

    this.freeDraw.painter?.selectedBrush.setSettings(options);
    this.parserText.painter.selectedBrush.setSettings(options);
  }

  public getSettings(): BrushSettings {
    return this.brushSettings;
  }

  protected update(): void {
    setInterval(() => {
      TWEEN.update();
    }, 1000 / 60);
  }

  public clearCanvas(): void {
    if (!this.ctx) return;
    this.ctx.clearRect(0, 0, Screen.width, Screen.height);
    this.ctx.setTransform(1, 0, 0, 1, 0, 0);
    this.ctx?.scale(this.scale, this.scale);
    this.translateX = 0;
  }

  public onMouseDown(e: MouseEvent) {
    if (this.freeDraw) this.freeDraw.startDraw(e.x, e.y);
  }

  public onMouseUp() {
    if (this.freeDraw) this.freeDraw.stopDrawing();
  }

  public onMouseMove(e: MouseEvent) {
    if (this.freeDraw) this.freeDraw.drawing(e.x, e.y);
  }
}
