import {
  ChangeEvent, FC, useCallback, useContext, useEffect, useState,
} from 'react';
import { ReactSVG } from 'react-svg';
import classNames from './Debug.module.css';
import { Screen } from '../../data/ScreenResolution/Screen';
import font from '../../assets/font.svg';
import font1 from '../../assets/font1.svg';
import font2 from '../../assets/font2.svg';
import font3 from '../../assets/font3.svg';
import font4 from '../../assets/font4.svg';
import font5 from '../../assets/font5.svg';
import { Easing } from '../../data/EasingAnimation/Easing';
import { MainApp } from '../../modules/MainApp/MainApp';
import json from '../../assets/json/easing.json';
import numbers from '../../assets/numbers.svg';
import symbols from '../../assets/symbols.svg';
import { ConfigProviderContext } from '../../context/ConfigProvider';
import PhraseCenterBuilder from '../../modules/MainApp/feature/Parser/PhraseCenterBuilder';

const Debug: FC = () => {
  const [word, setWord] = useState<string>('');
  const [mainApp, setMainApp] = useState<MainApp | null>(null);
  const [fontNumber, setFontNumber] = useState<number>(3);
  const [isAnimation, setIsAnimation] = useState<boolean>(false);
  const [isCenter, setIsCenter] = useState<boolean>(false);

  const { config } = useContext(ConfigProviderContext);

  const changeWordHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setWord(e.target.value);
  }, [setWord]);

  const clickButtonHandler = useCallback(() => {
    if (mainApp && word) {
      setIsAnimation(false);
      mainApp.parserText.destroy();
      const builderCentering = new PhraseCenterBuilder({ mainApp });
      const x1 = word.toLowerCase().replace(/\\n/g, ' /n');
      const wordArray = x1.split(' ');
      const wordArray2 = wordArray.filter((item) => item.length > 0);
      mainApp.parserText.countLineTime(wordArray2, 30000);
      if (isCenter) builderCentering.witeText(wordArray2);
      else {
        const withoutSlash = wordArray2.filter((item) => !item.includes('/n'));
        mainApp.parserText.currentTextIndex = 0;
        mainApp.parserText.parsePhrase(withoutSlash);
      }
    }
  }, [word, mainApp, fontNumber, isAnimation, isCenter]);

  const downloadJsonEasingHandler = useCallback(() => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    const jsonDownload = JSON.stringify(json, null, 2);
    const blob = new Blob([jsonDownload], { type: 'application/octet-binary' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = 'easingSettings.json';
    a.click();
    window.URL.revokeObjectURL(url);
  }, []);

  const selectChangeHandler = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    if (mainApp) mainApp.fontSettings.easingAnimation = e.target.value;
  }, [mainApp]);

  const selectChangeBgHandler = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    if (mainApp) {
      if (e.target.value === 'none') {
        mainApp.clearCanvas();
        return;
      }
      mainApp.setBackground(e.target.value);
    }
  }, [mainApp]);

  useEffect(() => {
    window.addEventListener('isAnimated', () => {
      setIsAnimation(true);
    });
  }, []);

  const clearCanvasHandler = useCallback(() => {
    if (mainApp) {
      mainApp.clearCanvas();
    }
  }, [mainApp]);

  const clearTextHandler = useCallback(() => {
    if (mainApp) {
      mainApp.clearCanvas();
      mainApp.setBackground(mainApp.pictureImg);
    }
  }, [mainApp]);

  useEffect(() => {
    if (!config) {
      return;
    }

    setTimeout(() => {
      const brush = new MainApp({
        svgId: 'svg', isDebug: true, config, background: config.config.background[0], isFarm: false,
      });
      setMainApp(brush);
    }, 1000);
  }, [config]);

  useEffect(() => {
    const onMouseDown = (e: MouseEvent) => {
      mainApp?.onMouseDown(e);
    };

    const onMouseUp = () => {
      mainApp?.onMouseUp();
    };

    const onMouseMove = (e: MouseEvent) => {
      mainApp?.onMouseMove(e);
    };

    const pressStart = (e: KeyboardEvent) => {
      if (e.code === 'Enter') {
        clickButtonHandler();
      }
    };

    window.addEventListener('mousedown', onMouseDown);
    window.addEventListener('mouseup', onMouseUp);
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('keypress', pressStart);

    return () => {
      window.removeEventListener('mousedown', onMouseDown);
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('keypress', pressStart);
    };
  }, [clickButtonHandler]);

  return (
    <div className={classNames.root}>
      <canvas
        id="canvas"
        width={2480}
        height={3508}
      />
      <div style={{ display: 'none' }}>
        <ReactSVG src={font} />
        <ReactSVG src={font1} />
        <ReactSVG src={font2} />
        <ReactSVG src={font3} />
        <ReactSVG src={font4} />
        <ReactSVG src={font5} />
        <ReactSVG src={numbers} />
        <ReactSVG src={symbols} />
      </div>
      <div className={classNames.debugPanel}>
        <input
          placeholder="text"
          value={word}
          onChange={changeWordHandler}
        />
        <input
          onChange={(e) => {
            setFontNumber(Number(e.target.value));
          }}
          value={fontNumber}
          placeholder="0-5"
        />
        <select onChange={selectChangeHandler} name="easing" id="easing">
          {Easing.map((item) => (
            <option key={item} value={item}>{item}</option>
          ))}
        </select>
        <select onChange={selectChangeBgHandler} name="bg" id="bg">
          {config?.config.background.map((item) => (
            <option key={item} value={item}>{item}</option>
          ))}
          <option value="none">none</option>
        </select>
        <button
          className={classNames.button}
          type="button"
          onClick={clickButtonHandler}
        >
          Start
        </button>
        <button
          className={classNames.button}
          type="button"
          onClick={downloadJsonEasingHandler}
        >
          download json easing
        </button>
        <button
          className={classNames.button}
          type="button"
          onClick={clearCanvasHandler}
        >
          Clear canvas
        </button>
        <button
          className={classNames.button}
          type="button"
          onClick={clearTextHandler}
        >
          clear text
        </button>
        <button
          className={classNames.button}
          type="button"
          onClick={() => {
            if (mainApp) {
              mainApp.parserText.isCenter = !isCenter;
              setIsCenter(!isCenter);
            }
          }}
        >
          {isCenter ? 'center' : 'basic'}
        </button>
      </div>
    </div>
  );
};

export default Debug;
