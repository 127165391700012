import { fabric } from 'fabric';
import { BrushSettings } from '../types/BrushSettings';

export interface BrushOptions extends BrushSettings {
  canvas: CanvasRenderingContext2D;
}

export abstract class Brush {
  protected ctx: CanvasRenderingContext2D;

  public width = 15;

  public opacity = 0.02;

  public point: fabric.Point = new fabric.Point(0, 0);

  public latest: fabric.Point = new fabric.Point(0, 0);

  public color = '#FF0000';

  public dispersion = 0;

  public dripAmount = 0;

  public dripWidth = 5;

  public dripLength = 5;

  public dripOpacity = 50;

  public dripCurvature = 10;

  public endDripSize = 5;

  public dripFlat = 0;

  public shadowBlur = 30;

  public dripSpeed = 20;

  protected scaleBlur: number = 1;

  constructor(options: BrushOptions) {
    if (!options.canvas) throw new Error('canvas in brush not enabled');
    this.ctx = options.canvas;
    this.setSettings(options);
  }

  public setSettings(options: BrushSettings): void {
    if (options.opacity !== undefined) this.opacity = options.opacity;
    if (options.width !== undefined) this.width = options.width;
    if (options.color !== undefined) this.color = options.color;
    if (options.dispersion !== undefined) this.dispersion = options.dispersion;
    if (options.dripLength !== undefined) this.dripLength = options.dripLength;
    if (options.dripOpacity !== undefined) this.dripOpacity = options.dripOpacity;
    if (options.dripCurvature !== undefined) this.dripCurvature = options.dripCurvature;
    if (options.endDripSize !== undefined) this.endDripSize = options.endDripSize;
    if (options.dripSpeed !== undefined) this.dripSpeed = options.dripSpeed;
    if (options.dripAmount !== undefined) this.dripAmount = options.dripAmount;
    if (options.dripWidth !== undefined) this.dripWidth = options.dripWidth;
    if (options.dripFlat !== undefined) this.dripFlat = options.dripFlat;
    if (options.shadowBlur !== undefined) this.shadowBlur = options.shadowBlur;
    if (options.blurScale !== undefined) this.scaleBlur = options.blurScale;
  }

  public setWidth(width: number): void {
    this.width = width;
  }

  public setPoint(point: any): void {
    this.latest = this.point;
    this.point = new fabric.Point(point.x, point.y);
  }

  public setOpacity(opacity: number): void {
    this.opacity = opacity;
  }

  public draw(x: number, y: number): void {
    this.ctx.stroke();
  }
}

export default Brush;
