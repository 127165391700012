import ParserWord from './ParserWord';
import ParserText from './ParserText';
import { Font } from '../../../CanvasText/Font';
import { MainApp } from '../../MainApp';
import Settings from '../ParseTextSettings/Settings';
import { calculateMaximumValueArray, splitToArray } from '../../utils/helpers';

export type TranslateWordType = {
  translate: { x: number, y: number }[];
  halfSummaryHeightRows: number;
}

export type ParsersType = ParserWord | ParserText;
// todo: нужно доделать здесь я пока буду узнавать центирование
export type FabricParserOptions = {
  parser?: ParsersType;
  mainApp: MainApp;
}

class PhraseCenterBuilder {
  public parser?: ParsersType;

  public mainApp: MainApp;

  private width: number = 2480;

  private height: number = 3508;

  constructor(options: FabricParserOptions) {
    this.parser = options.parser;
    this.mainApp = options.mainApp;
  }

  public witeText(phrase: string[]): void {
    const newPhrase = phrase.filter((word) => word !== '/n');

    const font = new Font({
      fontSize: this.mainApp.fontSettings.fontSize,
      buffer: this.mainApp.FontBuffer,
      letterSpacing: this.mainApp.fontSettings.letterSpacing,
    });
    const newWord: string[][] = [];
    for (let i = 0; i < newPhrase.length; i++) {
      const arr: string[] = [];
      for (let j = 0; j < newPhrase[i].length; j++) {
        const settings = new Settings(newPhrase[i][j]);
        const currentSetting = settings.checkAndReturnSettings();
        const id = currentSetting?.getIdValue();
        if (id) arr.push(id);
      }
      newWord.push(arr);
    }

    const allWidth: number[] = [];

    newWord.forEach((item) => {
      const widthText = font.countTextWidth(
        item,
        this.mainApp.brushSettings.width ?? 15,
        undefined,
        this.mainApp.parserText.kerning,
      );
      allWidth.push(widthText);
    });

    const { resultWidth } = splitToArray(phrase, allWidth);

    const allResults: number[] = [];

    resultWidth.forEach((widthArray) => {
      const result = widthArray.reduce((item, prev, currentIndex) => (item + prev) + (currentIndex * 140));
      allResults.push(result);
    });

    const maxWidth = calculateMaximumValueArray(allResults);

    const fontScale = this.width / maxWidth;

    let fontSize = (this.mainApp.fontSettings.fontSize * fontScale) - 20;
    let heightWord = font.getAdvancedGlyphHeight(newWord[0][0], fontSize);
    let resultHeightPhrase = (heightWord * resultWidth.length) + (this.mainApp.fontSettings.letterSpacing * resultWidth.length);
    if (resultHeightPhrase > 3408) {
      while (resultHeightPhrase > 3408) {
        fontSize -= 10;
        heightWord = font.getAdvancedGlyphHeight(newWord[0][0], fontSize);
        resultHeightPhrase = (heightWord * resultWidth.length) + (this.mainApp.fontSettings.letterSpacing * resultWidth.length);
      }
    }

    this.mainApp.setFontSettings({ ...this.mainApp.fontSettings, fontSize });

    const newCounter: number[] = [];

    newWord.forEach((item) => {
      const x = font.countTextWidth(
        item,
        this.mainApp.brushSettings.width ?? 15,
        this.mainApp.fontSettings.fontSize,
        this.mainApp.parserText.kerning,
      );
      newCounter.push(x);
    });

    const { resultIndex: resultIndex1, resultWidth: resultWidth1 } = splitToArray(phrase, newCounter);

    const halfSummaryHeight = this.height / 2;
    const { translate, halfSummaryHeightRows } = this.translateRow(resultWidth1, heightWord);

    const startY = halfSummaryHeight - halfSummaryHeightRows;
    const newY = resultIndex1.map((subArr) => subArr[0]);

    newY.shift();
    const { x } = translate[0];
    this.mainApp.translateY = startY;
    this.mainApp.translateX = x + 10;
    translate.shift();

    this.mainApp.parserText.startY = startY;
    this.mainApp.parserText.translateCenter = translate;
    this.mainApp.parserText.translatesY = newY;

    this.mainApp.parserText.parsePhrase(newPhrase);
  }

  private translateRow(phraseWidth: number[][], heightWord: number): TranslateWordType {
    const rows = phraseWidth.length;
    this.mainApp.parserText.wordHeight = heightWord;
    const summaryHeightRows = heightWord * rows;
    const halfSummaryHeightRows = summaryHeightRows / 2;
    return {
      translate: this.centeringPhrase(phraseWidth, halfSummaryHeightRows),
      halfSummaryHeightRows,
    };
  }

  private centeringPhrase(phraseWidth: number[][], halfSummaryHeightRows: number): {x: number, y: number}[] {
    const halfSummaryHeight = this.height / 2;
    const halfSummaryWidth = this.width / 2;
    const translate: { x: number, y: number }[] = [];
    for (let i = 0; i < phraseWidth.length; i++) {
      const currentRowWidth = phraseWidth[i];
      let summaryWidth = currentRowWidth.reduce((item, prev) => (item + prev));
      summaryWidth += phraseWidth.length * 30;
      const halfCurrentWidth = summaryWidth / 2;
      translate.push({ x: (halfSummaryWidth - halfCurrentWidth) + 10, y: halfSummaryHeight - halfSummaryHeightRows });
    }

    return translate;
  }
}

export default PhraseCenterBuilder;
