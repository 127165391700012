import { numberToGlyphId } from './utils/numberToGlyphId';
import { getBBoxSvg } from '../Converter/utils';
import { GlyphInterface } from './type/GlyphInterface';

export type GlyphOptions = {
  font: any;
}

class Glyph implements GlyphInterface {
  private _font: any;

  constructor(options: GlyphOptions) {
    this._font = options.font;
  }

  public getKerningGlyps(leftId: string, rightId: string): number {
    const leftGlyphId = numberToGlyphId(leftId);
    const rightGlyphId = numberToGlyphId(rightId);
    const glyphsArray = [];
    for (let i = 0; i < this._font.glyphs.length || 0; i++) glyphsArray.push(this._font.glyphs.get(i));
    const glyph = glyphsArray.find((glyph) => glyph.name.toUpperCase() === leftGlyphId.toUpperCase());
    const previewGlyph = glyphsArray.find((glyph) => glyph.name.toUpperCase() === rightGlyphId.toUpperCase());

    if (!glyph) throw new Error(`glyph ${glyph} not found`);

    return previewGlyph ? this._font.getKerningValue(previewGlyph, glyph) : 0;
  }

  public getAdvancedGlyphWidth(word: HTMLElement): number {
    return getBBoxSvg(word).width;
  }

  public getAdvancedGlyphHeight(word: HTMLElement): number {
    return getBBoxSvg(word).height;
  }
}

export default Glyph;
