export const Positions = [
  { y: 370, x: 124 },
  { y: 710, x: 124 },
  { y: 370, x: 1120 },
  { y: 1090, x: 124 },
  { y: 1090, x: 1120 },
  { y: 1800, x: 124 },
  { y: 1800, x: 1240 },
  { y: 2480, x: 124 },
  { y: 2480, x: 1120 },
  { y: 370, x: 124 },
];

// todo: need write formul for count positions
