import { convertSvgToCoordinates, parseSymbols } from '../../../Converter/utils';
import { SettingInterface } from '../../interfaces/SettingsInterface';

export class SymbolSetting implements SettingInterface {
  public symbol: string = '';

  public fontNumber?: number = undefined;

  constructor(symbol: string) {
    this.symbol = symbol;
  }

  public setFontNumber(font?: number): void {
    this.fontNumber = font;
  }

  public check(): boolean {
    return !!parseSymbols(this.symbol);
  }

  public getIdValue(): string {
    return parseSymbols(this.symbol) ?? '';
  }

  public getPathSummary(): string[] {
    return this.writeValue(this.getIdValue());
  }

  public writeValue(word: string): string[] {
    const { linePath, polylinePath } = convertSvgToCoordinates(word);
    return [linePath, polylinePath].flat(1);
  }
}
