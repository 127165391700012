import { BrushSettings } from '../../BrushTools/types/BrushSettings';

export const DefaultBrushSettings: BrushSettings = {
  width: 15,
  opacity: 0.1,
  color: '#FF0000',
  dispersion: 0,
  dripLength: 5,
  dripAmount: 0,
  dripWidth: 5,
  dripOpacity: 50,
  dripCurvature: 10,
  endDripSize: 5,
  dripSpeed: 20,
  dripFlat: 0,
};
