export interface GetBBoxSvgResult {
  width: number;
  height: number;
}

const getBBoxSvg = (word: HTMLElement): GetBBoxSvgResult => {
  const lines = word?.getElementsByTagName('line');
  const polyline = word?.getElementsByTagName('polyline');
  // @ts-ignore
  const allLines = [...lines, ...polyline];

  let minX = Infinity;
  let minY = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;

  allLines.forEach((line) => {
    if (line.tagName === 'line') {
      const x1 = parseFloat(line.getAttribute('x1'));
      const y1 = parseFloat(line.getAttribute('y1'));
      const x2 = parseFloat(line.getAttribute('x2'));
      const y2 = parseFloat(line.getAttribute('y2'));

      minX = Math.min(minX, x1, x2);
      minY = Math.min(minY, y1, y2);
      maxX = Math.max(maxX, x1, x2);
      maxY = Math.max(maxY, y1, y2);
    } else if (line.tagName === 'polyline') {
      const { points } = line;
      for (let i = 0; i < points.length; i++) {
        const { x } = points[i];
        const { y } = points[i];

        minX = Math.min(minX, x);
        minY = Math.min(minY, y);
        maxX = Math.max(maxX, x);
        maxY = Math.max(maxY, y);
      }
    }
  });

  return {
    width: maxX - minX,
    height: maxY - minY,
  };
};

export default getBBoxSvg;
