const calculateMaximumValueArray = (array: number[]): number => {
  let maximum = array[0];

  for (let i = 1; i < array.length; i++) {
    if (array[i] > maximum) maximum = array[i];
  }

  return maximum;
};

export default calculateMaximumValueArray;
