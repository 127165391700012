import * as TWEEN from '@tweenjs/tween.js';
import { interpolateBrushPoints, getEasingFunction } from './utils';
import BrushPoint from '../BrushTools/Brushes/BrushPoint';
import { MainApp } from '../MainApp/MainApp';
import { BrushTypes } from '../../types/types';
import { BrushSettings } from '../BrushTools/types/BrushSettings';
import { FontSettings } from '../CanvasText/type';
import easingJSON from '../../assets/json/easing.json';
import OwnBrushes from '../BrushTools/OwnBrushes';
import { Font } from '../CanvasText/Font';

export interface SvgPainterOptions {
  textCanvas: CanvasRenderingContext2D;
  mainApp: MainApp;
  brushSettings: BrushSettings;
  fontSettings: FontSettings;
}

export class SvgPainter {
  public points: any[] = [];

  public textCanvas: CanvasRenderingContext2D;

  public selectedBrush: BrushTypes;

  private fontScale = 1;

  public mainApp: MainApp;

  public font!: Font;

  // private lastTime: number = 0;

  public translate: { x: number, y: number } = { x: 0, y: 0 };

  constructor(options: SvgPainterOptions) {
    this.textCanvas = options.textCanvas;
    this.mainApp = options.mainApp;
    const brushesSettings = new OwnBrushes(this.textCanvas, this.mainApp);
    brushesSettings.setSelectedBrush({
      shadowBlur: options.brushSettings.shadowBlur,
      width: options.brushSettings.width,
      opacity: options.brushSettings.opacity,
      color: options.brushSettings.color,
      dispersion: options.brushSettings.dispersion,
      dripLength: options.brushSettings.dripLength,
      dripOpacity: options.brushSettings.dripOpacity,
      dripCurvature: options.brushSettings.dripCurvature,
      endDripSize: options.brushSettings.endDripSize,
      dripSpeed: options.brushSettings.dripSpeed,
      dripAmount: options.brushSettings.dripAmount,
      dripWidth: options.brushSettings.dripWidth,
      dripFlat: options.brushSettings.dripFlat,
    });
    this.selectedBrush = brushesSettings.selectedBrush;
    this.font = new Font({
      fontSize: options.fontSettings.fontSize,
      buffer: this.mainApp.FontBuffer,
      letterSpacing: options.fontSettings.letterSpacing,
    });
  }

  public setPoints(points: any[]): void {
    this.points = points;
  }

  public setScale(): void {
    this.fontScale = this.font.getScale();
  }

  public changeFontsParams(options: FontSettings): void {
    this.font.changeFontSize(options.fontSize);
  }

  public translateGlyph(id: string) {
    this.mainApp.translateX += this.font.getTranslateX(
      id,
      this.mainApp.previewId,
      this.selectedBrush.width,
      this.mainApp.parserText.kerning,
    );
  }

  public async drawGlyph(isAnimate: boolean, id: string, index: number): Promise<TWEEN.Tween<any>> {
    const newArr: BrushPoint[] = [];

    this.points.forEach((item) => {
      const point = new BrushPoint({ x: item.x * this.fontScale, y: item.y * this.fontScale });
      newArr.push(point);
    });
    const { interpolatePoints } = interpolateBrushPoints(newArr, 3);
    if (isAnimate) {
      const tween = await this.animateDraw(interpolatePoints, id.toUpperCase(), index);
      return Promise.resolve(tween);
    }
    interpolatePoints.forEach((point) => {
      this.selectedBrush.setPoint({ x: point.x, y: point.y });
      this.selectedBrush.draw(this.translate.x, this.translate.y);
    });

    return Promise.resolve(new TWEEN.Tween<any>({ length: 0 }).to({ length: 1 }, 0));
  }

  private animateDraw(interpolatePoints: BrushPoint[], id: string, index: number): Promise<TWEEN.Tween<any>> {
    const keyIndex = String(index);
    const word = easingJSON[id as keyof typeof easingJSON];
    // @ts-ignore
    const easingFunction = word[keyIndex as any];
    const func = this.mainApp.fontSettings.easingAnimation
      ? getEasingFunction(this.mainApp.fontSettings.easingAnimation) : getEasingFunction(easingFunction);
    let prevTime = 0;
    return new Promise((resolve) => {
      const tween = new TWEEN.Tween({ time: 0 })
        .to({ time: 1 }, this.mainApp.lineTime)
        .easing(func || undefined)
        .onUpdate(({ time }) => {
          const timer = Number(time.toFixed(4));
          this.setPointsByTime(interpolatePoints, prevTime, timer);
          prevTime = timer;
        })
        .onComplete(() => {
          this.selectedBrush.onEndOfLine(this.translate.x, this.translate.y);
        });

      const isFarm = process.env.REACT_APP_IS_FARM === 'true';
      if (!isFarm) {
        document.addEventListener('visibilitychange', (() => {
          // if (document.hidden) tween.pause();
          // else tween.resume();
        }));
      }

      resolve(tween);
    });
  }

  private setPointsByTime(interpolatePoints: BrushPoint[], timeFrom: number, timeTo: number): void {
    const currentTimer = Math.floor(interpolatePoints.length * timeTo);
    const lastTimer = Math.floor(interpolatePoints.length * timeFrom);
    for (let i = lastTimer; i < currentTimer; i++) {
      this.drawPoint(interpolatePoints[i]);
    }
  }

  private drawPoint(point: BrushPoint): void {
    if (point) {
      this.selectedBrush.setPoint({ x: point.x, y: point.y });
    }
    this.selectedBrush.draw(this.translate.x, this.translate.y);
  }
}
