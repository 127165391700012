export const Easing = [
  'QuadraticInOut',
  'QuadraticOut',
  'QuadraticIn',
  'CubicInOut',
  'CubicIn',
  'CubicOut',
  'SinusoidalInOut',
  'SinusoidalIn',
  'SinusoidalOut',
  'ExponentialInOut',
  'ExponentialIn',
  'ExponentialOut',
  'CircularInOut',
  'CircularIn',
  'CircularOut',
  'none',
];
