export const numberToGlyphId = (number: string) => {
  if (number === '0.1') return 'ZERO';
  if (number === '0.2') return 'ZERO';
  if (number === '1.1') return 'ONE';
  if (number === '1.2') return 'ONE';
  if (number === '2.1') return 'TWO';
  if (number === '2.2') return 'TWO';
  if (number === '3.1') return 'THREE';
  if (number === '3.2') return 'THREE';
  if (number === '4.1') return 'FOUR';
  if (number === '4.2') return 'FOUR';
  if (number === '5.1') return 'FIVE';
  if (number === '5.2') return 'FIVE';
  if (number === '6.1') return 'SIX';
  if (number === '6.2') return 'SIX';
  if (number === '7.1') return 'SIX';
  if (number === '7.2') return 'SEVEN';
  if (number === '8.1') return 'SEVEN';
  if (number === '8.2') return 'EIGHT';
  if (number === '9.1') return 'NINE';
  if (number === '9.2') return 'NINE';

  return number;
};
