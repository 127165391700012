import { CrayonBrush } from './Brushes';
import { BrushSettings } from './types/BrushSettings';
import { MainApp } from '../MainApp/MainApp';
import { BrushTypes } from '../../types/types';
import { BrushOptions } from './Brushes/Brush';

class OwnBrushes {
  private _selectedBrush!: BrushTypes;

  private canvas: CanvasRenderingContext2D;

  // too: убрать отсюда
  public mainApp: MainApp;

  constructor(canvas: CanvasRenderingContext2D, mainApp: MainApp) {
    this.canvas = canvas;
    this.mainApp = mainApp;
  }

  public get selectedBrush(): BrushTypes {
    return this._selectedBrush;
  }

  public setSelectedBrush(options: BrushSettings) {
    const params: BrushOptions = {
      canvas: this.canvas,
    };

    if (options.opacity !== undefined) params.opacity = options.opacity;
    if (options.width !== undefined) params.width = options.width;
    if (options.color !== undefined) params.color = options.color;
    if (options.dispersion !== undefined) params.dispersion = options.dispersion;
    if (options.dripWidth !== undefined) params.dripWidth = options.dripWidth;
    if (options.dripCurvature !== undefined) params.dripCurvature = options.dripCurvature;
    if (options.endDripSize !== undefined) params.endDripSize = options.endDripSize;
    if (options.dripSpeed !== undefined) params.dripSpeed = options.dripSpeed;
    if (options.dripOpacity !== undefined) params.dripOpacity = options.dripOpacity;
    if (options.dripLength !== undefined) params.dripLength = options.dripLength;
    if (options.dripAmount !== undefined) params.dripAmount = options.dripAmount;
    if (options.dripFlat !== undefined) params.dripFlat = options.dripFlat;

    this._selectedBrush = new CrayonBrush(params);
  }
}

export default OwnBrushes;
