import React, { FC } from 'react';
import './styles/App.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Canvas from './containers/Canvas/Canvas';
import Debug from './containers/Debug/Debug';
import ConfigProvider from './context/ConfigProvider';

const App: FC = () => (
  <div className="App">
    <ConfigProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Canvas />} />
          <Route path="/debug" element={<Debug />} />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  </div>
);

export default App;
